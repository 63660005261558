import {useState} from "react";
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import ErrorText from "./ErrorText"

export default function PhoneNumberInput({
	id,
	name = id,
	placeholder,
	phoneNumberDataRef = null,
	isPhoneNumberValid = true
}) {
	const [t] = useTranslation()
	const [phone, setPhone] = useState("")

	const handleChange = (value, data) => {
		let number = value

		if(value.startsWith("330") && data.countryCode === "fr"){
			number = "33" + number.slice(3)
		} else if (value.startsWith("0") && data.countryCode === "fr"){
			number = "33" + number.slice(1)
		}
		// for DOM-TOM
		else if ((data.countryCode === "gf" || data.countryCode === "pf" || data.countryCode === "gp" || data.countryCode === "mq") && value.startsWith(data.dialCode + "0")){
			number = data.dialCode + number.slice(4)
		}

		if (!!phoneNumberDataRef) {
			phoneNumberDataRef.current = { inputNumber: number, country: data }
			setPhone(number)
		}
	}

	return (
		<>
			{!isPhoneNumberValid && <ErrorText text={t("monoStepFunnel.formPanel.fields.error.incorrectNumber")} />}
			<PhoneInput
				className="phoneNumberFieldInputContainer"
				value={phone}
				inputProps={{
					name,
					id,
					autoFocus: false,
					className: "fieldInput isPhoneNumberFieldInput",
					placeholder
				}}
				country="fr"
				placeholder={placeholder}
				onChange={handleChange}
			/>
		</>
	)
}
